import { Controller } from 'stimulus'
import pDebounce from '../lib/p-debounce'

export default class extends Controller {
  static targets = [
    'coordinator',
    'collaborator',
    'coordinator_or_collaborator_or_admin',
    'canpublish',
    'institution',
    'permission',
    'access_vaccination_schedule',
    'has_access_to_services',
    'services',
    'access_mobile_apps'
  ]

  url = null

  connect() {
    this.check_if_can_publish_pages_visible()

    if (this.hasInstitutionTarget) {
      const el = this.institutionTarget
      const handleSearch = async event => {
        try {
          if (!this.url) return
        } catch (err) {
          console.error(err)
        }
      }
      const debouncedSearch = pDebounce(handleSearch, 500)
      el.addEventListener('search', debouncedSearch, false)
    }
  }

  can_publish_async(institution_id, target) {
    const url = `/admin2/instituciones/can_publish_pages?institution_id=${institution_id}`
    fetch(url)
      .then(resp => resp.json())
      .then(function (data) {
        if (data['can_publish_pages']) {
          target.classList.remove('hidden')
        } else {
          target.classList.add('hidden')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  check_if_can_publish_pages_visible() {
    if (!this.hasCanpublishTarget) {
      return
    }
    const permission_value = this.permissionTarget.value
    var list = this.collaboratorTarget.getElementsByTagName('label')
    var i,
      line,
      collaborator_page = 0
    for (i = 0; i < list.length; i++) {
      line = list[i].getElementsByTagName('input')[0]
      if (line.id == 'user_permissions_page' && line.checked) {
        collaborator_page = 1
      }
    }
    this.canpublishTarget.classList.add('hidden')
    let institution_id
    if (this.hasInstitutionTarget && (permission_value == 'coordinator' || collaborator_page)) {
      institution_id = this.institutionTarget?.value
      if (institution_id.length) this.can_publish_async(institution_id, this.canpublishTarget)
    }
  }

  check_section(event) {
    if (!this.hasCanpublishTarget) {
      return
    }
    const value = event.target.value
    const visible = !this.canpublishTarget.classList.contains('hidden')
    const checked = event.target.checked
    this.check_if_can_publish_pages_visible()
  }

  select_permission(event) {
    const value = event.target.value
    const currentUserHasAccessToServices = event.target.dataset.currentUserHasAccessToServices
    const currentUserRole = event.target.dataset.currentUserRole

    this.coordinatorTarget.classList.add('hidden')
    this.collaboratorTarget.classList.add('hidden')

    if (this.hasInstitutionTarget && this.hasServicesTarget && this.hasCanpublishTarget) {
      this.servicesTarget.classList.add('hidden')
      this.canpublishTarget.classList.add('hidden')
      if (value == 'coordinator' || value == 'collaborator' || value == 'admin')
        this.servicesTarget.classList.remove('hidden')
    }

    if (currentUserRole == 'admin' || currentUserHasAccessToServices === true) {
      this.servicesTarget.classList.remove('hidden')
    }

    if (currentUserRole == 'admin') {
      if (value == 'coordinator') {
        this.has_access_to_servicesTarget.classList.remove('hidden')
      } else {
        this.has_access_to_servicesTarget.classList.add('hidden')
      }
    }

    if (value == 'coordinator' || value == 'collaborator') {
      this.check_if_can_publish_pages_visible()
    }
    if (value == 'coordinator') this.coordinatorTarget.classList.remove('hidden')
    if (value == 'collaborator') this.collaboratorTarget.classList.remove('hidden')
    this.showhide_access_vaccination_schedule()
  }

  change_institution() {
    this.check_if_can_publish_pages_visible()
    this.choicesDocuments?.clearStore()
    this.showhide_access_vaccination_schedule()
    this.showHideProsecutorCheck()
    this.showHideMobileAppsCheck()
  }

  async showhide_access_vaccination_schedule() {
    if (this.hasInstitutionTarget && this.hasAccess_vaccination_scheduleTarget) {
      const permission_value = this.permissionTarget.value
      const institution = await this.getInstitution(this.institutionTarget.value)
      if (institution?.abbr === 'PCM' && (permission_value === 'coordinator' || permission_value === 'collaborator')) {
        this.access_vaccination_scheduleTarget.classList.remove('hidden')
      } else {
        this.access_vaccination_scheduleTarget.classList.add('hidden')
        document.getElementById('user_access_vaccination_schedule').checked = false
      }
    }
  }

  async showHideMobileAppsCheck() {
    if (this.hasInstitutionTarget && this.access_mobile_appsTarget) {
      const institution = await this.getInstitution(this.institutionTarget.value)
      const check_show_mobile_apps = /true/.test(institution?.check_show_mobile_apps)
      if (check_show_mobile_apps) {
        this.access_mobile_appsTarget.classList.remove('hidden')
      } else {
        this.access_mobile_appsTarget.classList.add('hidden')
        document.getElementById('user_can_access_mobile_apps_section').checked = false
      }
    }
  }

  async showHideProsecutorCheck() {
    const institution = await this.getInstitution(this.institutionTarget.value)
    const $prosecutorCheck = document.getElementById('user_permissions_prosecutor')
    if (institution?.abbr === 'MPFN') {
      $prosecutorCheck.closest('label').classList.remove('hidden')
    } else {
      $prosecutorCheck.closest('label').classList.add('hidden')
      $prosecutorCheck.checked = false
    }
  }

  async enableDisabledMFA() {
    const institution = await this.getInstitution(this.institutionTarget.value)
    const $mfaCheckBox = document.getElementById('user_use_multi_factor_authentication')
    const $mfaLabelCheckBox = document.getElementById('label_user_use_multi_factor_authentication')
    const $noteMfaActiveInstitution = document.querySelector('.js-note-mfa-active-institution')
    const enable_multi_factor_authentication = /true/.test(institution?.enable_multi_factor_authentication)
    const userMFAEnabled = $mfaCheckBox.checked
    const linkConfigInstitutionURL = enable_multi_factor_authentication
      ? `/admin2/instituciones/${institution.id}/configuracion`
      : ''

    $mfaCheckBox.disabled = enable_multi_factor_authentication
    $mfaCheckBox.checked = enable_multi_factor_authentication || userMFAEnabled
    $mfaCheckBox.dispatchEvent(new Event('change'))

    if (enable_multi_factor_authentication) {
      $mfaLabelCheckBox.classList.add('text-disabled-toggle')
    } else {
      $mfaLabelCheckBox.classList.remove('text-disabled-toggle')
    }

    $noteMfaActiveInstitution.innerHTML = `
      <span${!linkConfigInstitutionURL ? ' class="hidden"' : ''}>
        <span class="text-red-700 font-bold">Nota:</span> Esta funcionalidad está inhabilitada porque ha sido activada a nivel de la entidad. Puedes cambiar esta configuración desde
        <a class="underline font-bold" href="${linkConfigInstitutionURL}">
          Modificando entidad
        </a>.
      </span>
    `
  }

  async getInstitution(institutionId) {
    let institution = {}
    if (institutionId !== '') {
      const response = await fetch(`/admin2/instituciones/${institutionId}.json`)
      institution = await response.json()
    }
    return institution
  }
}
